import "../../scss/pages/Features.scss"
import { Link } from "gatsby"

import React from "react"
import SEO from "../../components/marketing/seo"
import Header from "../../components/marketing/Header/Header.jsx"
import Footer from "../../components/marketing/Footer/Footer.jsx"

import RunwayIcon from '!svg-react-loader!../../../content/assets/icn-runway.svg?name=RunwayIcon'
import ExpensesIcon from '!svg-react-loader!../../../content/assets/icn-expense-tracking.svg?name=ExpensesIcon'
import TaxesIcon from '!svg-react-loader!../../../content/assets/icn-estimated-tax.svg?name=TaxesIcon'

import taxAirplaneLady from '../../../content/assets/img-tax-airplane-lady.png'
import imgTaxScreen from '../../../content/assets/img-tax-screen.png'
import MailingListSignup from "../../components/marketing/MailingListSignup/MailingListSignup.jsx"



class EstimatedTaxes extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SEO title={`Hammock - Features - Estimated Quarterly Taxes for Freelancers`}
             description={`Ever have trouble figuring out your quarterly estimated tax payments? We give you an estimate based on your actual income and expenses.`}
             keywords={['taxes for freelancers', 'estimated quarterly tax', 'estimated quarterly tax payments', 'self-employed', 'selfemployed', 'freelance', 'freelancers', 'freelancing']}
        />

        <Header />

        <div className="feature-page container">
          <div className="row">
            <div className="feature-hero col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 col-xs-12">
              <TaxesIcon className="feature-hero-icon" />
              <h1>Estimated Taxes</h1>
              <p>Using your bank account transactions, we estimate your federal quarterly tax payment, based on your actual income and expenses.</p>
            </div>
          </div>
        </div>

        <div className="taxes-are-hard section grey px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-md-5 pb-5 pb-md-0">
                <h2>Taxes are hard. Let us help!</h2>
                <p>Don't waste your time trying to figure out what counts as a write-off or manually tracking all income to get a rough estimate. Stop hoarding cash in your account out of tax fear.</p>
                <p>Hammock figures out how much tax you'll owe on the income you made each quarter. We look at your income, expenses, tax bracket, and other nuanced factors to get you the most accurate estimate possible.</p>
                <a className="" href="https://app.tryhammock.com/signup" title="Signup for a 14-day FREE Trial">Sign Up for a 14-Day FREE Trial &#10230;</a>
              </div>
              <div className="col-lg-5 offset-lg-2 col-md-6 offset-md-1 col-sm-12">
                <img className="tax-airplane-lady" src={taxAirplaneLady} alt="Hammock takes the guesswork out of quarterly estimated tax payments for freelancers." />
              </div>
            </div>
          </div>
        </div>


        <div className="large-feature-image section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-md-12 col-sm-12 col-xs-12">
                <img className="feature-laptop-screenshot" src={imgTaxScreen} alt="Hammock helps you visualize and determine your federal quarterly estimated tax payments." />
              </div>
            </div>
          </div>
        </div>

        <div className="other-features section white px-4 px-sm-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-xs-12">
                <h6 className="section-heading">Other Features</h6>
                <div className="row">
                  <div className="col-6">
                    <Link to="/features/runway" className="feature-card">
                      <RunwayIcon className="feature-icon" />
                      <p>Runway Insights</p>
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link to="/features/expense-tracking" className="feature-card">
                      <ExpensesIcon className="feature-icon" />
                      <p>Expense Tracking</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MailingListSignup signupLocation="Feature Page: Estimated Taxes"/>

        <Footer />
      </React.Fragment>
    )
  }
}

export default EstimatedTaxes
